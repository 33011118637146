<template>
  <div>
    <BrandChooseWindow />
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo-all-dark.png" />
    </Header>
    <div class="bg_image bg_image--16 d-none d-md-block">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="rn-page-title text-center pt--100"></div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- <div class="rn-column-area rn-section-gap mt--150"> -->
    <v-container>
      <v-row>
        <v-col xl="12" lg="12" md="12" sm="12" cols="12">
          <div class="single-service service__style--3 text-white mb--50">
            <div class="">
              <h3 class="heading-title" style="font-size: 30px">
                Όροι Χρήσης
              </h3>
              <h3 class="heading-title" style="font-size: 20px">
                Εισαγωγή
              </h3>

              <p>
                Σας καλωσορίζουμε στο loyalty program Health_e Bonus Card του
                HHG. Το Hellenic Healthcare Group σας επιβραβεύει για την
                εμπιστοσύνη σας και, αξιοποιώντας τις νέες τεχνολογίες,
                καινοτομεί και δημιουργεί την εφαρμογή Health_e Bonus Card.
              </p>
              <p>
                Μέσα από την εφαρμογή θα αποκτήσετε την ψηφιακή κάρτα Health_e
                Bonus που σας δίνει τη δυνατότητα να απολαμβάνετε ξεχωριστά
                προνόμια σε όλα τα θεραπευτήρια και διαγνωστικά κέντρα του
                Ομίλου.
              </p>
              <p>
                Με κάθε σας συναλλαγή, μαζεύετε πόντους, ανεβαίνετε βαθμίδα και
                ανταμείβεστε για την επιλογή σας με ακόμα περισσότερες παροχές
                και μοναδικές προσφορές από τον μεγαλύτερο ιδιωτικό όμιλο
                παροχής υπηρεσιών υγείας στη χώρα μας, στον οποίο ανήκουν
                κορυφαία θεραπευτήρια, διαγνωστικά κέντρα και μια εταιρεία
                εμπορίας ιατροτεχνολογικών προϊόντων.
              </p>
              <p>
                Αυτοί οι Γενικοί όροι και προϋποθέσεις (οι «Όροι») συνιστούν
                τους νομικά δεσμευτικούς όρους που ισχύουν για τη χρήση της
                Υπηρεσίας από εσάς. Οι Όροι ισχύουν για όλες τις υπηρεσίες που
                παρέχει η Υπηρεσία HHG ή/και οι Επαγγελματίες υγείας της
                Υπηρεσίας. Να έχετε υπόψη ότι οι Όροι αποτελούν τη συμφωνία
                μεταξύ υμών («υμών » ή «ο χρήστης») και του ομίλου HHG, η οποία
                διέπει τα δικαιώματα και τις υποχρεώσεις σας κατά τη χρήση της
                Υπηρεσίας. Είναι σημαντικό να διαβάσετε και να κατανοήσετε
                αυτούς τους Όρους. Με την εγγραφή σας στην Υπηρεσία, πατώντας το
                κουμπί «Αποδοχή» ή άλλο παρεμφερές, με την εγκατάσταση ή τη
                σύνδεσή σας στην Υπηρεσία ή με οποιαδήποτε χρήση της Υπηρεσίας,
                αναγνωρίζετε και επιβεβαιώνετε ότι έχετε διαβάσει και κατανοήσει
                αυτούς τους Όρους και προϋποθέσεις χρήσης αυτής. Δια του
                παρόντος, σας ενημερώνουμε ότι η Υπηρεσία για να λειτουργεί και
                να εξυπηρετεί το σκοπό της, υποχρεούται να επεξεργάζεται
                προσωπικά δεδομένα και ευαίσθητα δεδομένα υγείας που σας αφορούν
                και παρέχετε συγκατάθεση για την επεξεργασία τους, όπως
                περιγράφεται στις Δηλώσεις προστασίας προσωπικών δεδομένων.
                Σύνοψη της Πολιτικής προστασίας προσωπικών δεδομένων μας
                μπορείτε να βρείτε
                <router-link
                  class="btn-transparent rn-btn-dark"
                  to="/privacy-policy"
                  ><a>εδώ <span style="font-size: 16px"></span></a></router-link
                >:
              </p>

              <p>
                Δηλώνετε και εγγυάστε ότι έχετε δικαιοπρακτική ικανότητα να
                αποδέχεστε και να τηρείτε αυτούς τους Όρους και τις προϋποθέσεις
                της παρούσας εφαρμογής, ότι έχετε διαβάσει και κατανοήσει πλήρως
                τους Όρους και ότι δεν έχετε μειωμένη ικανότητα κατανόησης
                τέτοιων ζητημάτων, καθώς και ότι δεν έχετε τεθεί σε δικαστική
                συμπαράσταση. Αποδέχεστε και δεσμεύεστε από τους παρόντες Όρους
                και τη Δήλωση προστασίας προσωπικών δεδομένων μας.
              </p>
              <p>
                Η χρήση της Υπηρεσίας προϋποθέτει αποδοχή των Όρων. Μην
                χρησιμοποιείτε, εγκαθιστάτε ή ανοίγετε την Υπηρεσία και μην
                εγγράφεστε ως χρήστης της Υπηρεσίας αν δεν αποδέχεστε ή
                κατανοείτε αυτούς τους Όρους. Αν η αποδοχή αυτών των Όρων είναι
                απαραίτητη πριν ή μετά από την εγκατάσταση του λογισμικού ή της
                Εφαρμογής σε σχέση με την Υπηρεσία και εσείς δεν αποδέχεστε
                αυτούς τους Όρους, δεν έχετε δικαίωμα να χρησιμοποιήσετε το εν
                λόγω λογισμικό ή την Εφαρμογή. Σε τέτοιες περιπτώσεις,
                παρακαλείστε να διαγράφετε το εν λόγω λογισμικό ή την Εφαρμογή
                και τυχόν αντίγραφά τους αμέσως. Ενδέχεται να τροποποιήσουμε ή
                να αλλάξουμε τους Όρους ανά πάσα στιγμή. Σε αυτές τις
                περιπτώσεις, θα σας ζητηθεί να αποδεχτείτε τους ενημερωμένους
                όρους. Σε περίπτωση μη αποδοχής των ανανεωμένων όρων, η χρήση
                της υπηρεσίας διακόπτεται.
              </p>
              <p>
                Αν έχετε οποιαδήποτε απορία ή ερώτηση σχετικά με αυτούς τους
                Όρους, μπορείτε να επικοινωνήσετε μαζί μας στα στοιχεία
                επικοινωνίας που θα βρείτε στο τέλος των Όρων.
              </p>
              <h3 class="heading-title" style="font-size: 20px">
                Εγγραφή
              </h3>

              <p>
                Για να χρησιμοποιήσετε την Υπηρεσία απαιτείται εγγραφή, αποδοχή
                των Όρων Χρήσης, ενημέρωση για την Πολιτική Προστασίας Δεδομένων
                και συνεχής συμμόρφωση με τους Όρους. Για να εγγραφείτε, πρέπει
                να συμπληρώσετε το όνομα, το επώνυμο και το μοναδικό αριθμό ΑΜΚΑ
                σας. Πρέπει να είστε τουλάχιστον 18 ετών για να εγγραφείτε και
                να χρησιμοποιήσετε την Υπηρεσία. Αυτή η υπηρεσία προορίζεται
                μόνο για προσωπική χρήση. Δηλώνετε και εγγυάστε ότι θα
                χρησιμοποιήσετε την Υπηρεσία σύμφωνα με τους παρόντες όρους και
                σε συμμόρφωση με την ισχύουσα νομοθεσία.
              </p>
              <p>
                Η χρήση της Υπηρεσίας απαγορεύεται στις χώρες και τις περιοχές
                όπου είναι παράνομη. Συναινείτε να μην χρησιμοποιείτε την
                Υπηρεσία σε τέτοιες χώρες.
              </p>
              <p>
                Αναλαμβάνετε πλήρως την ευθύνη να παρέχετε ακριβείς και σαφείς
                πληροφορίες σε σχέση με τη χρήση της Υπηρεσίας. Αν οι
                πληροφορίες που μας παρέχετε καταστούν αργότερα εσφαλμένες ή
                παραπλανητικές, πρέπει να μας ενημερώσετε αμέσως για τυχόν
                τέτοιες αλλαγές.
              </p>
              <p>
                Διατηρούμε το δικαίωμα να απορρίψουμε την εγγραφή για
                οποιονδήποτε λόγο. Η εγγραφή μπορεί να περιορίζεται, για
                παράδειγμα, στην περιοχή.
              </p>
              <h3 class="heading-title" style="font-size: 20px">
                Ενεργοποίηση και λειτουργία
              </h3>

              <p>
                Με την ενεργοποίηση της Health_e bonus Card έχετε ήδη στη
                διάθεσή σας έναν αριθμό πόντων ως επιβράβευση για την
                εμπιστοσύνη σας στο HHG. Στη συνέχεια, με κάθε συναλλαγή,
                συλλέγετε πόντους. Μόλις συλλέξετε τον απαραίτητο αριθμό πόντων,
                ανεβαίνετε βαθμίδα και απολαμβάνετε ακόμα περισσότερες παροχές
                και μοναδικές προσφορές από τον μεγαλύτερο ιδιωτικό όμιλο
                παροχής υπηρεσιών υγείας στη χώρα μας. Δηλαδή περνάτε διαδοχικά
                από την Health_e Bonus White στην Health_e Bonus Blue κ.ο.κ.
              </p>
              <p>
                Η χρήση της υπηρεσίας είναι δωρεάν, τόσο η αρχική ενεργοποίηση,
                όσο και οποιαδήποτε αναβάθμισή της. Η ψηφιακή κάρτα είναι
                απολύτως προσωπική και μοναδική όσο και ο ΑΜΚΑ σας. Αφορά
                ενήλικα άτομα (18+). Δεν μεταβιβάζεται σε άλλα άτομα και το
                περιεχόμενό της δεν μεταφέρεται σε άλλη κάρτα άλλου ατόμου.
                Ουσιαστικά πρόκειται για την προσωπική σας «θυρίδα» στο δίκτυο
                HHG όπου καταγράφονται και αθροίζονται οι πόντοι που αφορούν τις
                συναλλαγές σας. Με αυτόν τον τρόπο, εξασφαλίζεται αφενός η
                ασφάλεια των προσωπικών σας στοιχείων και αφετέρου η ασφάλεια
                των συναλλαγών.
              </p>
              <p>
                Σας ενημερώνουμε ότι η Υπηρεσία τίθεται σε λειτουργία
                ηλεκτρονικά με τη συγκατάθεσή σας αμέσως μετά την έναρξη της
                Υπηρεσίας. Έχετε υπόψη ότι για την πρόσβαση στην Υπηρεσία
                απαιτείται πρόσβαση στο διαδίκτυο για την οποία ο πάροχός σας
                μπορεί να σας επιβάλει πρόσθετες χρεώσεις. Αποτελεί δική σας
                ευθύνη να ενημερωθείτε και να αναλάβετε το κόστος για τις
                επικοινωνίες, το υλικό και το λογισμικό σας.
              </p>

              <h3 class="heading-title" style="font-size: 20px">
                Πληροφορίες ασθενούς και εμπιστευτικότητας
              </h3>

              <p>
                Σε αυτήν την εφαρμογή τα αρχεία ασθενών που δημιουργούνται,
                αποθηκεύονται, γνωστοποιούνται και τηρούνται εμπιστευτικά
                σύμφωνα με το ισχύον νομοθετικό πλαίσιο. Ο υπεύθυνος
                επεξεργασίας δεδομένων, σύμφωνα με το Γενικό Κανονισμό για την
                Προστασία Προσωπικών Δεδομένων 679/2016 είναι το Διαγνωστικό και
                Θεραπευτικό Κέντρο Υγεία Μονοπρόσωπη Α.Ε., μέλος του Ομίλου HHG.
                Αναφέρεται ότι σύμφωνα με την ελληνική νομοθεσία, τα ιατρικά
                αρχεία διατηρούνται από τον Υπεύθυνο Επεξεργασίας δεδομένων για
                διάρκεια (είκοσι) 20 ετών.
              </p>
              <p>
                Το υποκείμενο των δεδομένων μπορεί να ασκήσει το δικαίωμα
                πρόσβασης στα δεδομένα, διόρθωσης, συμπλήρωσης, διαγραφής,
                περιορισμού, εναντίωσης ως προς αυτά, καθώς και φορητότητας των
                δεδομένων και ανάκλησης της όποιας παρεχόμενης συγκατάθεσης
                παρασχεθεί στα πλαίσια αυτής της εφαρμογής οποτεδήποτε (χωρίς
                αναδρομική ισχύ) μέσω ηλεκτρονικού μηνύματος στη διεύθυνση [<a
                  href="mailto:gdpr1@hygeia.gr"
                  >gdpr1@hygeia.gr</a
                >] ή ταχυδρομικώς στη διεύθυνση: Φλέμινγκ 14, 15123 Μαρούσι,
                Ελλάδα. Τα στοιχεία επικοινωνίας του Υπεύθυνου προστασίας
                δεδομένων του ΥΓΕΙΑ είναι: Δημήτρης Κολιός, (tel:+302106867679).
                Σε περίπτωση που δεν μπορεί να επιλυθεί κάποιο ζήτημα, το
                υποκείμενο δεδομένων διατηρεί το δικαίωμα να υποβάλει καταγγελία
                στην
                <a
                  href="https://www.dpa.gr/el/polites/katagelia_stin_arxi"
                  target="_blank"
                >
                  Ελληνική Αρχή Προστασίας Προσωπικών Δεδομένων.</a
                >
              </p>
              <p>
                Τυχόν συγκατάθεση πρέπει να παρέχεται ελεύθερα, να είναι
                συγκεκριμένη, εμπεριστατωμένη και σαφής ένδειξη της επιθυμίας
                του υποκειμένου δεδομένων με την οποία, μέσω δήλωσης ή με σαφή
                καταφατική πράξη, να δηλώνει τη συναίνεση για την επεξεργασία
                των προσωπικών δεδομένων που το αφορούν, όπου αυτή απαιτείται.
                Για να ανακαλέσει ο ασθενής-χρήστης αυτής της εφαρμογής τη
                συγκατάθεσή του μπορεί να ενημερώσει τον όμιλο ΥΓΕΙΑ μέσω
                ηλεκτρονικού μηνύματος στη διεύθυνση (<a
                  href="mailto:gdpr1@hygeia.gr"
                  >gdpr1@hygeia.gr</a
                >) ή ταχυδρομικά στη διεύθυνση: Υπεύθυνος προστασίας δεδομένων,
                Φλέμινγκ 14, 15123 Μαρούσι, Ελλάδα.
              </p>
              <h3 class="heading-title" style="font-size: 20px">
                Δικαιώματα πνευματικής ιδιοκτησίας
              </h3>

              <p>
                Αυτοί οι Όροι σας παρέχουν το δικαίωμα να χρησιμοποιείτε την
                Υπηρεσία μόνο για όσο διαρκούν αυτοί οι Όροι. Όλα τα δικαιώματα
                πνευματικής ιδιοκτησίας και άλλα δικαιώματα, συμπεριλαμβανομένων
                όλων των πνευματικών δικαιωμάτων, των διπλωμάτων ευρεσιτεχνίας
                και των εμπορικών απορρήτων της Υπηρεσίας, συμπεριλαμβανομένης
                της Τοποθεσίας και της Εφαρμογής, παραμένουν στο HHG. Δεν έχετε
                δικαίωμα αντιγραφής, αποθήκευσης, αναπαραγωγής, τροποποίησης ή
                εκχώρησης της Υπηρεσίας ή τμήματος αυτής. Ωστόσο, ο Χρήστης έχει
                δικαίωμα να αποθηκεύει μεμονωμένες πληροφορίες για τον εαυτό του
                σύμφωνα με το σκοπό της Υπηρεσίας και αυτών των Όρων.
              </p>
              <h3 class="heading-title" style="font-size: 20px">
                Υπηρεσίες που παρέχονται από τρίτους
              </h3>

              <p>
                Η Υπηρεσία μπορεί επίσης να παρέχει πληροφορίες ή συνδέσμους για
                υπηρεσίες τρίτων. Ο όμιλος HHG δεν φέρει ευθύνη για υπηρεσίες
                τρίτων ή για τυχόν πληροφορίες, πράξεις ή παραλείψεις αυτών.
              </p>
              <h3 class="heading-title" style="font-size: 20px">
                Απαγορευμένες πράξεις
              </h3>

              <p>
                Η παρακάτω λίστα περιέχει παραδείγματα δραστηριοτήτων που
                απαγορεύονται για την Υπηρεσία:
              </p>
              <p>- Πλαστοπροσωπία</p>
              <p>- Αθέμιτη ή παράνομη χρήση της Υπηρεσίας</p>
              <p>
                - Η μετάδοση τυχόν πληροφοριών, υλικού, περιεχομένου ή
                πληροφοριών που είναι προσβλητικές, υποτιμητικές, άσεμνες,
                πλαστές, άκυρες, πα ραβατικές ή με άλλον τρόπο παράνομες
              </p>
              <p>
                - Μετάδοση ή δημοσίευση ιών, κακόβουλου λογισμικού ή άλλου
                κακόβουλου κώδικα στην Υπηρεσία
              </p>
              <p>- Τροποποίηση ή με άλλον τρόπο παραποίηση της Υπηρεσίας</p>
              <p>- Παρεμπόδιση ή απενεργοποίηση της Υπηρεσίας.</p>
              <p>
                Για να διασφαλίζουμε το απαραβίαστο και την ασφάλεια της
                Υπηρεσίας, διατηρούμε το δικαίωμα, οποτεδήποτε, χωρίς αιτία και
                χωρίς προειδοποίηση, να αποκλείουμε την πρόσβαση στην Υπηρεσία.
              </p>

              <p>
                Κάθε χρήστης είναι υπεύθυνος για όλες τις έμμεσες και
                επακόλουθες ζημιές, συμπεριλαμβανομένων των ζημιών τρίτων, που
                μπορεί να προκύψουν από τη χρήση της Υπηρεσίας κατά παράβαση της
                νομοθεσίας, αυτών των Όρων ή άλλων ορθών πρακτικών.
              </p>

              <h3 class="heading-title" style="font-size: 20px">
                Προσωπικά δεδομένα και ασφάλεια
              </h3>
              <p>
                Η δέουσα προστασία του ιδιωτικού απορρήτου και των προσωπικών
                δεδομένων σας και των δεδομένων υγείας σας είναι πολύ σημαντική
                για εμάς. Η Υπηρεσία μπορεί και υποχρεούται να αποθηκεύει και να
                επεξεργάζεται τα προσωπικά δεδομένα και τα δεδομένα υγείας των
                χρηστών της εφαρμογής-ασθενών της. Οι πληροφορίες αυτές είναι
                εμπιστευτικές και προστατεύονται βάσει του Κώδικα Ιατρικής
                Δεοντολογίας (Νόμος 3418/2005), του Γενικού Κανονισμού για την
                Προστασία Δεδομένων Προσωπικού Χαρακτήρα 679/2016 και του
                εφαρμοστικού εθνικού νόμου 4624/2019 και η επεξεργασία και χρήση
                τους γίνεται μόνο σύμφωνα με αυτούς τους Όρους και την
                υφιστάμενη ισχύουσα νομοθεσία. Ο Κώδικας Ιατρικής Δεοντολογίας
                και το υφιστάμενο νομοθετικό πλαίσιο καθορίζουν τον τρόπο και το
                είδος των δεδομένων που πρέπει να αποθηκεύονται για την υπηρεσία
                παροχής απομακρυσμένης υγειονομικής περίθαλψης και φροντίδας
                ασθενών. Αυτά τα δεδομένα αποτελούν το αρχείο των δεδομένων
                υγείας και είναι ξεχωριστό αρχείο από το Μητρώο Πελατών του
                ομίλου HHG. Για λεπτομερείς πληροφορίες σχετικά με τον τρόπο
                χειρισμού των προσωπικών δεδομένων και δεδομένων υγείας των
                χρηστών της εφαρμογής - ασθενών, μπορείτε να ανατρέξετε στην
                <router-link
                  class="btn-transparent rn-btn-dark"
                  to="/privacy-policy"
                  ><a
                    >Πολιτική Προστασίας προσωπικών δεδομένων.
                    <span style="font-size: 16px"></span></a
                ></router-link>
              </p>
              <p>
                Με τη χρήση της Υπηρεσίας, παρέχετε τη συγκατάθεση για τη
                συλλογή των προσωπικών σας δεδομένων και τη χρήση της Υπηρεσίας
                σύμφωνα με την Πολιτική προστασίας προσωπικών δεδομένων μας και
                την ισχύουσα νομοθεσία και τους κανονισμούς.
              </p>
              <p>
                Καθίσταται σαφές ότι έχετε τη δυνατότητα να ζητήσετε τη διαγραφή
                των προσωπικών σας δεδομένων οποτεδήποτε και εμείς οφείλουμε να
                συμμορφωθούμε με το γραπτό αίτημά σας. Στην περίπτωση των
                δεδομένων υγείας, έχουμε νομική υποχρέωση να διατηρούμε αυτά τα
                αρχεία για χρονικό διάστημα 20 ετών από την τελευταία επίσκεψη
                στην εφαρμογή.
              </p>
              <p>
                Σας ενημερώνουμε ότι τα δεδομένα ασθενών αποθηκεύονται σε ένα
                κεντρικό μητρώο μέσω τεχνολογίας cloud (σύννεφο), το οποίο
                διατηρεί το HHG και, ανάλογα με τη συγκατάθεσή σας, στην
                κεντρική τήρηση μητρώων. Εφαρμόζονται όλα τα αναγκαία τεχνικά
                και οργανωτικά μέτρα ασφάλειας. Τα μητρώα ασθενών διέπονται από
                εμπιστευτικότητα και απόρρητο. Τυχόν γνωστοποίηση σε τρίτους,
                μπορεί να λάβει χώρα βάσει ρητής διάταξης νόμου ή κατόπιν δικής
                σας γραπτής άδειας και συγκατάθεσης. Έχετε τη δυνατότητα να
                ελέγξετε τις πληροφορίες που αποθηκεύσατε στο μητρώο μας ανά
                πάσα στιγμή μέσω της εφαρμογής ή με γραπτό αίτημα μέσω
                ηλεκτρονικού ταχυδρομείου ή αυτοπροσώπως στον όμιλο Υγεία Α.Ε.
                που είναι ο Υπεύθυνος Επεξεργασίας των δεδομένων. Το δικαίωμα
                ελέγχου μπορεί να μην ισχύει μόνο σε εξαιρετικές περιπτώσεις.
                Ομοίως, έχετε δικαίωμα να αιτηθείτε τη διόρθωση τυχόν εσφαλμένων
                πληροφοριών στο μητρώο μας ή να ανακαλέσετε ή να περιορίσετε τη
                συγκατάθεσή σας.
              </p>
              <p>
                Είστε ενήμεροι και συναινείτε ότι οι επαγγελματίες που
                εμπλέκονται στη φροντίδα σας θα έχουν πλήρη πρόσβαση στις
                πληροφορίες που διατηρούνται στην Υπηρεσία, συμπεριλαμβανομένων
                τυχόν δικών σας δημοσιεύσεων. Σε περίπτωση που δεν το
                επιθυμείτε, θα πρέπει να το δηλώσετε ειδικά σε πεδίο που
                εμφανίζεται με κάθε επίσκεψή σας.
              </p>
              <h3 class="heading-title" style="font-size: 20px">
                Ευθύνη για τα δεδομένα που έχουν δηλωθεί
              </h3>
              <p>
                Δια του παρόντος δηλώνετε και συναινείτε ότι δεν θα μας
                παράσχετε ψευδή προσωπικά δεδομένα και δεδομένα υγείας και δεν
                θα δημιουργήσετε λογαριασμό χρήστη για κανέναν άλλον εκτός από
                τον εαυτό σας. Έχετε την πλήρη ευθύνη για την ακρίβεια και
                αλήθεια των δεδομένων που μας δηλώνετε μονομερώς με την εγγραφή
                σας στην παρούσα εφαρμογή. Κατανοείτε και συναινείτε ότι οι
                υπηρεσίες δικτύου και κινητής τηλεφωνίας δεν είναι απολύτως
                ασφαλείς. Είστε υπεύθυνοι για την κατάλληλη ασφάλεια των
                συσκευών, των συστημάτων, των αναγνωριστικών και των εργασιών.
                Βεβαιωθείτε ότι προστατεύετε δεόντως τα στοιχεία ταυτότητάς σας
                που συνδέονται με την Υπηρεσία. Είστε υπεύθυνοι για κάθε χρήση
                της Υπηρεσίας από το λογαριασμό σας.
              </p>
              <h3 class="heading-title" style="font-size: 20px">
                Περιορισμός ευθύνης
              </h3>
              <p>
                Η ευθύνη για τη χρήση της υπηρεσίας μέσω της συσκευής σας είναι
                δική σας. Κατανοείτε και συναινείτε ότι η Υπηρεσία παρέχεται σε
                εσάς με τη μορφή που περιγράφεται στους παρόντες όρους χρήσης.
              </p>
              <p>
                Οι πληροφορίες και οι συμβουλές που σας παρέχονται από τους
                Επαγγελματίες υγείας μέσω της Υπηρεσίας θα χρησιμοποιούνται ως
                οδηγός και ως αρχική σύσταση για δράση ή θεραπεία. Σε κάθε
                περίπτωση, η ευθύνη για την Υπηρεσία και η ευθύνη των
                Επαγγελματιών υγείας είναι σύμφωνη και σε συμμόρφωση με την
                ισχύουσα νομοθεσία. Οι Επαγγελματίες υγείας που παρέχουν την
                Υπηρεσία, οι ιδιοκτήτες, οι νόμιμοι εκπρόσωποι, οι διευθυντές,
                τα μέλη του διοικητικού συμβουλίου, οι εργαζόμενοι, οι μέτοχοι,
                οι συνεργάτες, οι διαφημιστές, οι θυγατρικές ή συνεργαζόμενες
                εταιρείες, δεν φέρουν οιαδήποτε ευθύνη για τυχόν άμεσες, θετικές
                ή αποθετικές ζημιές, ζημία ένεκα ηθικής βλάβης/ψυχικής οδύνης ή
                βλάβης της προσωπικότητας, ενδοσυμβατική ή αδικοπρακτική ευθύνη
                (όπως, ενδεικτικά, τραυματισμοί, σωματική βλάβη, παραποίηση,
                απώλεια δεδομένων, απώλεια χρήσης, κατάχρηση, απώλεια εσόδων)
                που προκύπτουν από τη χρήση σας ή την αδυναμία χρήσης της
                Υπηρεσίας, του περιεχομένου της Υπηρεσίας ή άλλου περιεχομένου
                ή/και πληροφοριών που λαμβάνετε μέσω της Υπηρεσίας. Σε κάθε
                περίπτωση, η ευθύνη του ομίλου HHG συμμορφώνεται με την ισχύουσα
                νομοθεσία.
              </p>
              <p>
                Η υπηρεσία παρέχεται ως μια πλατφόρμα εφαρμογής μέσω της οποίας
                διατίθενται απομακρυσμένα πληροφορίες για την υγεία και ανάλογες
                παρεχόμενες υπηρεσίες. Δεν μπορούμε να εγγυηθούμε ή να ελέγξουμε
                την ακρίβεια, την ορθότητα, την ποιότητα, την κάλυψη, την
                καταλληλότητα ή την αξιοπιστία τυχόν πληροφοριών που παρέχονται
                στην Υπηρεσία, ούτε τον τρόπο χρήσης από τον εκάστοτε χρήστη της
                εφαρμογής/ασθενή. από τον ασθενή μέσω της Υπηρεσίας.
              </p>
              <h3 class="heading-title" style="font-size: 20px">
                Ισχύουσα νομοθεσία και επίλυση διαφορών
              </h3>
              <p>
                Οι όροι αυτοί διέπονται από την ελληνική νομοθεσία και αρμόδια
                δικαστήρια για την όποια ανακύπτουσα διαφορά είναι τα δικαστήρια
                της πόλης των Αθηνών.
              </p>
              <h3 class="heading-title" style="font-size: 20px">
                Επικοινωνία
              </h3>
              <p>
                Η υπηρεσία παρέχεται από τον όμιλο HHG. Για απορίες αναφορικά με
                αυτούς τους Όρους ή την Υπηρεσία, στείλτε μας μήνυμα
                ηλεκτρονικού ταχυδρομείου στη διεύθυνση
                <a href="mailto:info@hhg.gr">info@hhg.gr</a>
              </p>

              <p>
                Μην αποστέλλετε πληροφορίες για την υγεία ή άλλα ευαίσθητα
                δεδομένα μέσω ηλεκτρονικού ταχυδρομείου, καθώς δεν είναι
                ασφαλές.
              </p>
              <h3 class="heading-title" style="font-size: 20px">
                Ισχύς και λήξη
              </h3>
              <p>
                Διατηρούμε το δικαίωμα για αλλαγή ή τερματισμό της Υπηρεσίας
                οποτεδήποτε, για οποιονδήποτε λόγο, χωρίς ειδοποίηση, καθώς και
                για τη λήξη ή αναστολή της πρόσβασής σας στην Υπηρεσία ή/και το
                λογαριασμό σας. Μετά τη λήξη, όλες οι παράμετροι αυτών των Όρων
                οι οποίες εκ φύσεως προορίζονται να παραμείνουν σε ισχύ παρά τον
                τερματισμό της άδειας χρήσης παραμένουν σε ισχύ.
              </p>
              <p>
                Σε κάθε περίπτωση, όλα τα δεδομένα υγείας σας που θα
                δημιουργηθούν από τη χρήση της παρούσας Υπηρεσίας, παραμένουν
                στο ΔΘΚΑ ΥΓΕΙΑ Α.Ε., μέλος του Ομίλου HHG, που έχει οριστεί
                Υπεύθυνος Επεξεργασίας των προσωπικών δεδομένων και δεδομένων
                υγείας των χρηστών της εφαρμογής/ασθενών.
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
      <p></p>
    </v-container>
    <!-- </div> -->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/HeaderOnePage";
import Footer from "@/components/footer/FooterTwo";
import BrandChooseWindow from "@/components/brand/BrandChooseWindow";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  components: {
    Header,
    Footer,
    BrandChooseWindow,
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["token"]),
  },
  async created() {
    await this.getUser();
  },
  methods: {
    ...mapActions(["getUser"]),
    ...mapActions(["getToken"]),
  },
};
</script>
<style scoped>
.bg_image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 55px;
}
</style>
